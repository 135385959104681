import React, { Component } from "react";
import { Fade } from 'react-reveal';
import { Helmet } from "react-helmet";
import scrollTo from 'gatsby-plugin-smoothscroll';
import IndexNav from "../components/indexNav";
import BackLink from "../components/back-up";

class Home extends Component {
  constructor() {
    super()
  
    this.state = {
      offset: 0
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.parallaxShift);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.parallaxShift);
  }
  parallaxShift = () => {
    this.setState({
      offset: window.pageYOffset / 2
    });
  };

  render() {
    console.log('the offset: ' + this.state.offset);
    return (
      <div className={'home-content'}>
        <Helmet>
          <body id={'home'}></body>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"></meta>
          <title>Wes Wong: Sr Product Designer &amp; Director</title>
          <meta name="description" content="Design portfolio of Wes Wong"></meta>
        </Helmet>
        <div className={'hero-section'}>
          <section className={'yours-truly'} >
            <h1 className={'slide-top a1'}>Wes Wong</h1>
            <p className={'slide-top a2'}>Digital Product Design + User Experience</p>
          </section>
          <section className={'select-work'} >
            <Fade duration={1700}>
            <h2>Selected Work</h2>
            </Fade>
            <IndexNav />
          </section>
        </div>
        
        <div className={'about-link'}>
        <Fade delay={2500}>  
          <a onClick={() => scrollTo('#about-section')} >About</a>
        </Fade>
        </div>
        
        <section id={'about-section'} className={'about'}>
          <div>
          <p className={'firstP'}>
          Hi I&rsquo;m Wes, a&nbsp;digital product&nbsp;designer born &amp;&nbsp;raised in the Bay Area. For the last {(new Date().getFullYear())-2004} years, I&rsquo;ve helped startups &amp; Fortune 50 companies build their web, mobile &amp; 10ft user experiences. I&rsquo;m a fan of clean typography, quantitative insights, single page applications, &amp; well executed user&nbsp;experiences.
          </p><p>
          You can catch me at the water cooler geeking out about new typefaces, sandwiches, passive income, 90&rsquo;s hip-hop &amp; everything in&nbsp;between.          
          </p><p className={'endnote'}>
          *This portfolio was coded in React/Gatsby.          
          </p>
          </div>
        </section>
        <BackLink />
      </div>
    )
  }
}

export default Home;